import React, { useState, useEffect, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

//services
import serverService from '../services/server_service'
import localStorageService from '../services/local_storage';

//cmps
import Loader from '../cmps/Loader';
import Hero from '../cmps/Hero';
import Navbar from '../cmps/Navbar';
import Footer from '../cmps/Footer';
import NavMenu from '../cmps/NavMenu';
import NavSearch from '../cmps/NavSearch';
import NavChat from '../cmps/NavChat';
import TopNavUser from '../cmps/TopNavUser';
import DepositBonus from '../cmps/DepositBonus';
import HeroLoggedin from '../cmps/HeroLoggedin';
import RoowardBonus from '../cmps/RoowardBonus';
import RoobetPartners from '../cmps/RoobetPartners';
// import HalloweenBonus from '../cmps/HalloweenBonus';
import ChristmasBonus from '../cmps/ChristmasBonus';


// imgs
import logoImg from "../images/logo.png";
import searchImg from '../svg/search.svg';
import HistoryTable from '../cmps/HistoryTable';




function Home() {
    const navigate = useNavigate();
    const [loggedinUser, setLoggedinUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isNavOpened, setIsNavOpened] = useState(false);
    const [sideNavWidth, setSideNavWidth] = useState(68);
    const [isScreenLargerThan1200, setIsScreenLargerThan1200] = useState(false);
    const [isMenuSelected, setIsMenuSelected] = useState(false)
    const [isSearchSelected, setIsSearchSelected] = useState(false)
    const [isChatSelected, setIsChatSelected] = useState(false)
    const [selectedOption, setSelectedOption] = useState('default')

    //get user-ip and pass it to server
    useEffect(() => {
        serverService.saveIp()
        const user = localStorageService.readFromLocalStorage("user")
        if (user) {
            setLoggedinUser(user)
            serverService.serverLogger(`${user} is connected`)
        }

        const isRedirectedFromCashier = localStorageService.readFromLocalStorage("isRedirectFromCashier")
        if (isRedirectedFromCashier) {
            localStorageService.deleteFromLocalStorage("isRedirectFromCashier")
        }
        const isRedirectedFromLogin = localStorageService.readFromLocalStorage("isRedirectedFromLogin")
        if (isRedirectedFromLogin) {
            localStorageService.deleteFromLocalStorage("isRedirectedFromLogin")
        }
    }, []);

    //Loader timer
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
        }, 1500);
    }, [])

    //Close all nav options
    const closeAllNavOptions = useCallback(() => {
        setIsMenuSelected(false);
        setIsSearchSelected(false);
        setIsChatSelected(false);
        setSelectedOption('default');
    }, []);

    //Check screen size open nav for 1200px
    useEffect(() => {
        const handleResize = () => {
            setIsScreenLargerThan1200(window.innerWidth >= 1200);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isScreenLargerThan1200) {
            setIsNavOpened(true)
            setSideNavWidth(220)
        } else {
            setIsNavOpened(false)
            setSideNavWidth(68)
        }
    }, [isScreenLargerThan1200]);


    //toggle side nav open close
    const toggleSideNav = () => {
        setIsNavOpened(!isNavOpened)
        setSideNavWidth(sideNavWidth === 68 ? 220 : 68);
    };

    // Toggle nav menu
    function toggleMenu() {
        setIsMenuSelected(prevState => !prevState);
        setIsSearchSelected(false);
        setIsChatSelected(false);
        setSelectedOption(isMenuSelected ? 'default' : 'menu');
    }

    // Toggle nav search
    function toggleSearch() {
        setIsSearchSelected(prevState => !prevState);
        setIsMenuSelected(false);
        setIsChatSelected(false);
        setSelectedOption(isSearchSelected ? 'default' : 'search');
    }

    // Toggle nav chat
    function toggleChat() {
        setIsChatSelected(prevState => !prevState);
        setIsMenuSelected(false);
        setIsSearchSelected(false);
        setSelectedOption(isChatSelected ? 'default' : 'chat');
    }

    const handleLoginClick = () => {
        navigate('/modal=auth&tab=login');
    };

    const renderSelectedComponent = () => {
        switch (selectedOption) {
            case 'menu':
                return <NavMenu />
            case 'search':
                return <NavSearch toggleSearch={toggleSearch} />
            case 'chat':
                return <NavChat toggleChat={toggleChat} />
            default:
                return <>
                    {loggedinUser ? <HeroLoggedin /> : <Hero />}
                    <DepositBonus loggedinUser={loggedinUser} />
                    {/* <HalloweenBonus /> */}
                    {/* <ChristmasBonus /> */}
                    <RoowardBonus loggedinUser={loggedinUser} />
                    <div className='more-promotions-link'>
                        <a href="https://promotions.roobet.com/">Browse more...</a>
                    </div>
                    <RoobetPartners />
                    <HistoryTable />
                    <Footer />
                </>
        }
    };

    return (<>
        {isLoading && <Loader />}


        <div className='main'>
            <div className="home-side-nav">
                <div className="home-side-nav-toggle-btn" onClick={toggleSideNav}>
                    {!isNavOpened ? <svg className="Ui-root css-e14dx6" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g className="Ui-stroke" stroke="#fff" strokeLinecap="round" strokeWidth="2"><path d="m18 16v-8"></path><path d="m5 12h8"></path><path d="m10.1538 7.84619 3.6923 4.00001-3.6923 4"></path></g></svg>
                        : <svg className="Ui-root css-e14dx6" fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g className="Ui-stroke" stroke="#fff" strokeLinecap="round" strokeWidth="2"><path d="m5 16v-8"></path><path d="m18 12h-8"></path><path d="m12.8462 7.84619-3.69232 4.00001 3.69232 4"></path></g></svg>}
                </div>
                <div className='flex-col' style={{ width: `${sideNavWidth}px` }}>
                    <Navbar
                        isNavOpened={isNavOpened}
                        toggleMenu={toggleMenu}
                        toggleSearch={toggleSearch}
                        toggleChat={toggleChat}
                        isMenuSelected={isMenuSelected}
                        isSearchSelected={isSearchSelected}
                        isChatSelected={isChatSelected}
                        closeAllNavOptions={closeAllNavOptions}
                    />

                </div>
            </div>

            <div className="home-container">
                <div className="home-header">
                    <a href='/' className='home-header-logo-container'>
                        <img className="logo-img" alt="logo" src={logoImg} />
                    </a>

                    <div className="home-header-actions">
                        <div className='search-btn'><img src={searchImg} alt="search" /></div>
                        <div className={loggedinUser ? 'home-header-loggedin-user-container' : 'home-header-login-container'}>
                            {loggedinUser ?
                                <TopNavUser loggedinUser={loggedinUser} />
                                :
                                (<><button className='styled-btn-secondary' onClick={handleLoginClick}>Login</button>
                                    <a href='https://roobet.com/?modal=auth&tab=register'
                                        className='styled-btn-small'>Register</a></>)}
                        </div>
                    </div>
                </div>


                <div className="home-main-content">
                    <div className='home-main-content-spacer'></div>

                    {renderSelectedComponent()}
                </div>

            </div>
        </div>
    </>
    );
}

export default Home;
