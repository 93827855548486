// imgs
import ChristmasLigthTop from "../images/bonus/christmas-ligth-top.png";
import ChristmasLigthBottom from "../images/bonus/christmas-ligth-bottom.png";


function ChristmasBonus() {



    return (
        <div className="bonus-card" style={{ overflow: 'unset', position: 'relative' }}>


            <div className="bonus-hero christmas-img-bg">
                <img className="christmas-ligth top" src={ChristmasLigthTop} alt="HalloLeftRight" />
                <div className="bg-default"></div>
                <div className="bg-flash"></div>
                <img className="christmas-ligth bottom" src={ChristmasLigthBottom} alt="HalloLeftHand" />
            </div>

            <div className="bonus-description">
                <div className="header">Christmas Raffle</div>
                <div className="text-container">
                    <div>
                        Earn tickets with every qualified wager for a chance to win $400,000.<br />The winners will be drawn, and your winnings will be added to your Roobet balance.
                        <br />
                        Don't miss out on the fun!
                    </div>
                </div>
                <div className="christmas-btn-container">
                    <a href="https://roobet.com/raffle/weeklyraffle1223" className="styled-btn">
                        Learn More
                    </a>
                    <svg className="christmas-btn-svg-top" fill="none" viewBox="0 0 50 27"><path fill="#D9D1F4" d="m48.25 16.688 1.403-4.56c.23-.748.347-1.526.347-2.31C50 4.397 45.604 0 40.181 0H9.183C6.805 0 6.014 3.184 8.116 4.297c.254.134.53.22.816.251l10.717 1.19a8 8 0 0 1 4.182 1.76l2.413 1.974a4.55 4.55 0 0 0 5.402.264l1.266-.844a2.334 2.334 0 0 1 3.55 1.34L38 16l.477 3.813A4.78 4.78 0 0 0 48 19.22v-.87c0-.564.084-1.124.25-1.662"></path><path fill="#F2EEFF" fill-rule="evenodd" d="M45.782 15.874a4 4 0 1 0-5.563 0 2.994 2.994 0 0 0 .896 3.459 2 2 0 1 0 3.772 0 2.99 2.99 0 0 0 .895-3.459" clip-rule="evenodd"></path><path fill="#fff" fill-rule="evenodd" d="M43.748 15.667A2.499 2.499 0 0 0 42.5 11a2.5 2.5 0 0 0-1.248 4.667 1.5 1.5 0 1 0 2.495 0" clip-rule="evenodd"></path><path fill="#F2EEFF" d="M8.135 3.506C6.465 2.55 7.14 0 9.065 0h31.71a8.46 8.46 0 0 1 6.475 3.015l.054.063a2.025 2.025 0 0 1-.118 2.736A4.05 4.05 0 0 1 44.322 7h-3.52A3.37 3.37 0 0 1 38 5.5l-.293-.44a2.382 2.382 0 0 0-3.92-.063l-.537.753a4.18 4.18 0 0 1-3.4 1.75h-.62a5.38 5.38 0 0 1-3.807-1.577l-.173-.173A5.98 5.98 0 0 0 21.025 4H9.997c-.653 0-1.295-.17-1.862-.494"></path><path fill="#fff" d="M9 1a1 1 0 0 1 1-1h30a1 1 0 1 1 0 2H10a1 1 0 0 1-1-1"></path></svg>
                    <svg class="christmas-btn-svg-bottom" fill="none" viewBox="0 0 46 40"><path fill="#9789CD" d="m6.663 31.105-5.238-8.73A10 10 0 0 1 0 17.23V9l7.718 6.946A8 8 0 0 0 13.07 18H41a4 4 0 0 1 4 4 2 2 0 0 1-2 2h-.634a5.27 5.27 0 0 0-4.423 2.406l-2.223 3.436a5.82 5.82 0 0 1-8.223 1.606l-2.488-1.742a3.91 3.91 0 0 0-5.634 1.263l-.247.432a4.76 4.76 0 0 0-.628 2.365v.734a5.5 5.5 0 1 1-11 0v-.373c0-1.065-.29-2.11-.837-3.022"></path><path fill="#D9D1F4" fill-rule="evenodd" d="M9.717 30.285a4 4 0 1 1 6.567 0c.14.379.216.788.216 1.215a3.5 3.5 0 0 1-1.335 2.75 2.5 2.5 0 1 1-4.331 0 3.49 3.49 0 0 1-1.117-3.965" clip-rule="evenodd"></path><path fill="#F2EEFF" fill-rule="evenodd" d="M11.6 30.572a2.5 2.5 0 1 1 2.8 0 2 2 0 1 1-2.8 0" clip-rule="evenodd"></path><path fill="#D9D1F4" fill-opacity="0.5" fill-rule="evenodd" d="M29.5 28.5a2.5 2.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0" clip-rule="evenodd"></path><path fill="#D9D1F4" d="m23.63 15.111-.26-.222A12 12 0 0 0 15.56 12H7a7 7 0 0 1-7-7v4c0 6.627 5.373 12 12 12h30.232c1.122 0 1.639-1.396.787-2.127a3.63 3.63 0 0 0-2.36-.873h-9.22a12 12 0 0 1-7.81-2.889"></path><path fill="#fff" d="M15.56 12H8a8 8 0 0 1-8-8v3c0 6.627 5.373 12 12 12h28.293a.5.5 0 0 0 0-1h-8.854a12 12 0 0 1-7.81-2.889l-.259-.222A12 12 0 0 0 15.56 12"></path></svg>
                </div>
                <div className="terms">All <a href="https://roobet.com/terms-and-conditions">terms of service</a> apply to this offer</div>
            </div>

        </div>

    )
}

export default ChristmasBonus;